<template>
    <div
        role="alert"
        class="alert m-0 p-0 overflow-hidden"
        :class="`alert-${variant}`"
    >
        <div class="p-2">
            <slot />
        </div>
        <div
            v-if="duration"
            class="progress"
        >
            <div
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                :aria-valuenow="~~progress"
                class="progress-bar"
                :class="`bg-${variant}`"
                :style="`width: ${progress.toFixed(3)}%`"
            />
        </div>
    </div>

</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, computed, watch} from 'vue'

const progressAnimationDuration = .25

const props = defineProps({
    variant: {
        type: String,
        default: 'secondary',
        validator: variant => ['primary', 'secondary', 'success', 'danger', 'light', 'dark'].includes(variant),
    },
    start: [Number, Date],
    until: [Number, Date],
    duration: Number,
})
const emit = defineEmits(['elapsed'])

const duration = computed(() => props.duration || props.until && (new Date(props.until).getTime() - new Date().getTime()) / 1000)

const now = ref()
const start = ref()
const updateIntervalId = ref()
const progress = computed(() => duration.value && Math.max(0, Math.min(100, 100 * (progressAnimationDuration * 1000 + (now.value - start.value)) / (duration.value * 1000))))

watch(progress, progress => {
    if (progress >= 100) {
        clearInterval(updateIntervalId.value)
        updateIntervalId.value = undefined
        emit('elapsed')
    }
})

onMounted(() => {
    if (duration.value) {
        now.value = new Date().getTime()
        start.value = (props.start ? new Date(props.start) : new Date()).getTime()
        updateIntervalId.value = setInterval(() => now.value = new Date().getTime(), progressAnimationDuration * 1000)
    }
})
onBeforeUnmount(() => updateIntervalId.value && clearInterval(updateIntervalId.value))
</script>

<style scoped>
.alert > .progress {
    height: 3px;

    .progress-bar {
        transition-duration: v-bind('`${progressAnimationDuration}s`');
        transition-timing-function: linear;
    }
}
</style>
