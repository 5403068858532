import {Values} from './Values.js'

export class Sessions {

    static STORAGE_KEY = 'an3x-session'

    static validateSessionId(sessionId) {
        return Values.isString(sessionId) && sessionId.match(/[0-9a-z]{18}/) && sessionId
    }

    static createSessionId() {
        return `${Math.random().toString(36).slice(2, 12)}${new Date().getTime().toString(36).slice(0, 9)}`
    }

    static restore() {
        const item = window.localStorage.getItem(Sessions.STORAGE_KEY)
        const {sessionId, ...session} = item && JSON.parse(item) || {}
        return !Sessions.validateSessionId(sessionId) ? Sessions.initialize(session) : {
            ...session,
            sessionId,
        }
    }

    static persist(values) {
        const session = {
            ...(Sessions.restore() || {}),
            ...values
        }
        window.localStorage.setItem(Sessions.STORAGE_KEY, JSON.stringify(session))
        return session
    }

    static initialize(data = {}) {
        const sessionId = Sessions.createSessionId()
        const session = {...data, sessionId}
        window.localStorage.setItem(Sessions.STORAGE_KEY, JSON.stringify(session))
        return session
    }
}