<template>
    <section>
        <h2>{{ title }}</h2>
        <MoviePlayer
            :video="video"
            :locale="locale"
            show-description
            @locale="setLocale"
        />
    </section>
</template>

<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import MoviePlayer from '../components/content/MoviePlayer.vue'

const props = defineProps({
    videoKey: {
        type: String,
        required: true,
    },
    locale: String,
})

const router = useRouter()
const route = useRoute()
const store = useStore()

const videoKey = computed(() => {
    const {videoKey} = props
    return videoKey
})
const userLocale = computed(() => store.getters.locale)
const movies = computed(() => store.getters.movies(userLocale.value))
const movie = computed(() => (movies.value || []).find(({key}) => key === videoKey.value))
const title = computed(() => {
    const {title} = movie.value || {}
    return title
})
const video = computed(() => ({
    key: videoKey.value,
    locale: props.locale,
    preload: 'metadata',
}))

const setLocale = locale => {
    const {name} = route || {}
    router.replace({
        name,
        params: {
            videoKey: videoKey.value,
            locale,
        },
    })
}
</script>
