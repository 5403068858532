import {HttpError} from './HttpError.js'
import {Objects} from './Objects.js'

export class HttpClient {

    static JSON_HEADER = {'Content-Type': 'application/json'}

    constructor({baseUrl, timeout: defaultTimeout = 10} = {}) {
        baseUrl = new URL(baseUrl || '/', window.location.href)
        const getUrl = url => new URL(url, baseUrl).href
        const getOptions = ({headers, ...options}) => ({
            ...(!headers ? {} : {headers}),
            ...(!options ? {} : options),
        })
        const request = (method, url = '', {data = null, headers = null, timeout = defaultTimeout} = {}) => {
            const abortController = new AbortController()
            const timeoutId = setTimeout(() => abortController.abort(), timeout * 1000)

            return fetch(url = getUrl(url), getOptions({
                method,
                signal: abortController.signal,
                ...(!data ? {} : {body: JSON.stringify(data)}),
                ...(!headers ? {} : {headers}),
            }))
                .catch(error => {
                    console.error('Error making request', error, {url})
                    clearTimeout(timeoutId)
                    if (abortController.signal.aborted) {
                        throw new HttpError(method, url, 408, 'request-timeout', 'HttpError', `Request timed out after ${defaultTimeout}s`)
                    }
                    throw error
                })
                .then(response => {
                    clearTimeout(timeoutId)

                    return response
                        .text()
                        .catch(error => {
                            console.error('Error getting response body text', error)
                            throw error
                        })
                        .then(body => {
                            if (!response.ok) {
                                const {error, code, message, data} = JSON.parse(body) || {}
                                const {type, status, statusText} = response
                                throw new HttpError(method, url, status, code, error || type, message || statusText, data)
                            }
                            const isJson = Object
                                .entries(HttpClient.JSON_HEADER)
                                .some(([key, value]) => value === response.headers.get(key))

                            return !isJson ? body : JSON.parse(body)
                        })
                })
        }
        const appendSearchParams = (data, url) => !data ? url : `${url}${url.includes('?') ? '&' : '?'}${new URLSearchParams(Object.fromEntries(Object
            .entries(data)
            .map(([key, value]) => [key, !Objects.isObject(value) || !Array.isArray(value) ? value : JSON.stringify(value)]),
        ))}`

        let nextTimeout = defaultTimeout
        this.withTimeout = timeout => (nextTimeout = timeout || defaultTimeout) && this

        this.get = (url = '', data = null, headers = null) => request('GET', appendSearchParams(data, url), {headers, timeout: nextTimeout})
        this.post = (url = '', data = null, headers = null) => request('POST', url, {data, headers, timeout: nextTimeout})
        this.put = (url = '', data = null, headers = null) => request('PUT', url, {data, headers, timeout: nextTimeout})
        this.delete = (url = '', data = null, headers = null) => request('DELETE', url, {data, headers, timeout: nextTimeout})
        this.header = url => request('HEADER', url)
    }
}

