<template>
    <span
        v-if="content"
        ref="container"
        v-html="content"
    />
</template>

<script setup>
import {computed, ref} from 'vue'
import {MarkdownProps, useMarkdown} from '../../lib/useMarkdown.js'
import {useRouterLinks} from '../../lib/useRouterLinks.js'

const props = defineProps({
    source: {
        type: String,
        default: '',
    },
    ...MarkdownProps,
})

const container = ref()

const markdown = useMarkdown(props)
const content = computed(() => markdown.renderInline(props.source))

useRouterLinks(container)
</script>
