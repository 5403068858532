import locales from './locales.yml'
import langCodes from './lang_codes.yml'
import langNames from './lang_names.yml'
import translation from '../../public/content/en/translation.yml'
import {createI18n} from 'vue-i18n'
import {Objects} from '../lib/Objects.js'
import {nextTick} from 'vue'

export class Internationalization {
    i18n
    fallbackLocale

    constructor({locale, fallbackLocale = 'en'} = {}) {
        const createMessages = messages => ({...messages, locales})
        const getLocale = () => navigator.language
            .split('-')
            .shift()

        const i18n = createI18n({
            legacy: false,
            locale: locale || getLocale() || fallbackLocale,
            fallbackLocale,
            messages: {
                en: createMessages(translation),
            },
        })

        this.setLocale = locale => {
            i18n.global.locale.value = locale
            window.document.querySelector('html').lang = locale
        }
        this.getTranslation = locale => {
            const message = i18n.global.getLocaleMessage(locale)
            return Objects.isEmpty(message) ? undefined : message
        }
        this.addTranslation = (locale, translation) => {
            if (!this.getTranslation(locale)) {
                i18n.global.setLocaleMessage(locale, createMessages(translation))
            }
            return nextTick()
        }

        this.getLanguageName = code => (langCodes[code] || '')
            .split(',')
            .find(name => langNames[name.trim()])

        Objects.setProperties(this, {i18n, fallbackLocale})
    }

    get locale() {
        return this.i18n.global.locale.value
    }

    get translation() {
        return this.getTranslation(this.locale)
    }
}