export class HttpError extends Error {
    method
    url
    status
    code
    type
    message
    data

    constructor(method, url, status, code, type, message, data) {
        super(message)

        const properties = {method, url, status, code, type, message, data}
        Object
            .entries(properties)
            .forEach(([key, value]) => Object.defineProperty(this, key, {get: () => value}))

        this.clone = clone => HttpError.fromProperties({...properties, ...clone})
        this.toString = () => `${method}@${url} (${status}) ${[code, type, message].filter(_ => _).join(' ')} ${JSON.stringify(data)}`
    }

    static fromProperties({method, url, status, code, type, message, data}) {
        return new HttpError(method, url, status, code, type, message, data)
    }
}