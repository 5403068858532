import {HttpClient} from '../lib/HttpClient.js'

export const VideoProgressStore = {
    state: {},
    actions: {
        trackVideoProgress: ({getters: {httpClient, sessionId, videoProgresses}, commit}, {key, locale, progress}) => {
            const progresses = videoProgresses(key)
            return progresses && progresses.includes(progress) ? Promise.resolve() : commit('addVideoProgress', {key, progress}) || httpClient
                .post(`/api/${key}/${locale}`, {sessionId, progress}, HttpClient.JSON_HEADER)
                .catch(console.error)
        },
    },
    mutations: {
        addVideoProgress: (state, {key, progress}) => state[key] = [...(state[key] || []), progress],
    },
    getters: {
        videoProgresses: state => key => state[key],
    },
}