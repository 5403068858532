<template>
    <div class="contact-form">
        <Collapsible :expanded="!submitted">
            <form @submit.stop.prevent="submit">
                <div class="mb-3">
                    <label
                        for="message"
                        class="form-label"
                    >
                        {{ $t('contact.yourMessage') }}:
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        ref="message"
                        :disabled="disabled"
                        rows="5"
                        required
                        class="form-control"
                    />
                    <div class="form-text text-end">
                        {{ $t('form.required') }}
                    </div>
                </div>
                <div class="mb-3">
                    <label
                        for="email"
                        class="form-label"
                    >
                        {{ $t('contact.yourEmailAddress') }}:
                    </label>
                    <input
                        id="email"
                        name="email"
                        ref="email"
                        type="email"
                        required
                        :disabled="disabled"
                        aria-describedby="emailHelp"
                        class="form-control"
                    />
                    <div class="form-text text-end">
                        {{ $t('form.required') }}
                    </div>
                </div>
                <div class="text-end">
                    <button
                        type="submit"
                        :disabled="disabled"
                        class="btn btn-primary d-inline-flex align-items-center"
                    >
                        <Spinner
                            v-if="busy"
                            small
                            class="me-2"
                        />
                        {{ $t('form.submit') }}
                    </button>
                </div>
            </form>
        </Collapsible>
        <Collapsible
            lazy
            :expanded="submitted"
            :freeze="{success, failure, nextContact}"
        >
            <template #default="{frozen: {success, failure, nextContact}}">
                <Alert
                    v-if="success"
                    variant="success"
                    :start="lastContact"
                    :until="nextContact"
                    @elapsed="clearSuccess"
                >
                    <p class="m-0 text-center">{{ $t('contact.success') }}</p>
                </Alert>
                <Alert
                    v-else-if="failure"
                    variant="danger"
                    :duration="5"
                    @elapsed="clearFailure"
                >
                    <p class="text-center">{{ $t('contact.failure') }}</p>
                    <code class="d-block px-2 small text-center">
                        {{ failure }}
                    </code>
                </Alert>
            </template>
        </Collapsible>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import Spinner from './Spinner.vue'
import Collapsible from './Collapsible.vue'
import Alert from './Alert.vue'

const backoff = 5

const store = useStore()

const contacts = computed(() => store.getters.contacts || [])
const lastContact = computed(() => contacts.value.length && Math.max(...contacts.value) || 0)
const nextContact = computed(() => lastContact.value + contacts.value.length * backoff * 1000)

const busy = ref(false)
const success = ref(nextContact.value > new Date().getTime())
const failure = ref('')

const sessionId = computed(() => store.getters.sessionId)
const email = ref()
const message = ref()

const submitted = computed(() => success.value || !!failure.value)
const disabled = computed(() => busy.value || success.value || !!failure.value)

const clearFailure = () => failure.value = ''
const clearSuccess = () => success.value = false
const submit = ({target: form}) => {
    busy.value = true
    store
        .dispatch('sendContactMessage', {
            sessionId: sessionId.value,
            email: email.value.value,
            message: message.value.value,
        })
        .then(() => {
            success.value = true
            form.reset()
        })
        .catch(error => {
            const {message} = error
            failure.value = message || error.toString()
            console.error(error)
        })
        .then(() => busy.value = false)
}
</script>
