<template>
    <div class="text-center">
        <div
            class="position-relative w-100 mw-100"
            style="aspect-ratio: 16 / 9"
        >
            <Spinner
                v-if="loading"
                cover-absolute
                class="h-auto"
                style="aspect-ratio: 16 / 9;"
            />
            <div
                v-if="!started"
                class="poster position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
                :style="`background-image: url('${imageUrl}')`"
                @click="videoPlayer.play()"
            >
                <div class="play d-flex align-items-center justify-content-center">
                    <span />
                </div>
            </div>
            <video
                ref="videoPlayer"
                :key="`${videoKey}-${src}-${subtitleItems.length}`"
                :data-src="src"
                controls
                :preload="resumable ? 'auto' : preload"
                width="640"
                height="360"
                :poster="imageUrl"
                class="w-100 h-100"
                @play="onPlay"
                @timeupdate="onTimeupdate"
                @loadedmetadata="onLoadedMetadata"
                @canplay="onCanPlay"
            >
                <source
                    :src="src"
                    type="video/mp4"
                />
                <track
                    v-for="{locale, label, file, isDefault} in subtitleItems"
                    :key="locale"
                    :label="label"
                    kind="subtitles"
                    :srclang="locale"
                    :src="`/vid/${file}`"
                    :default="isDefault"
                />
            </video>
        </div>
        <div class="my-3">
            <h3
                v-if="shortDescription"
                class="m-0"
            >
                {{ shortDescription }}
            </h3>
            <div
                v-if="locales"
                class="d-flex align-items-center justify-content-center my-2"
            >
                <span class="me-2">{{ $t('audioTrack') }}:</span>
                <LanguageDropdown
                    :model-value="videoLocale"
                    :locales="locales"
                    @update:modelValue="setVideoLocale"
                />
            </div>
            <div class="row align-items-center justify-content-center my-0">
                <div class="col-auto my-2">
                    {{ duration }}
                </div>
                <div
                    v-if="subtitleItems.length"
                    class="col-auto my-2"
                >
                    {{ $t('subtitlesAnnouncement', {count: subtitleItems.length}) }}
                </div>
                <div class="buttons col-auto my-2">
                    <a
                        class="btn btn-sm btn-icon btn-secondary"
                        :href="src"
                        download
                    >
                        <InlineSvg src="/assets/svg/download.svg" />
                        {{ $t('download') }}
                    </a>
                    <button
                        v-if="shareHref"
                        type="button"
                        class="btn btn-sm btn-icon btn-secondary"
                        :class="!shareCollapseVisible ? 'btn-secondary' : shareSuccess ? 'btn-success' : 'btn-danger'"
                        @click="onShareUrl"
                    >
                        <InlineSvg src="/assets/svg/share.svg" />
                        {{ $t('share') }}
                    </button>
                </div>
            </div>
            <Collapsible
                lazy
                :expanded="shareCollapseVisible"
                :freeze="{shareSuccess}"
            >
                <template #default="{frozen: {shareSuccess}}">
                    <div class="px-2">
                        <Alert
                            :variant="shareSuccess ? 'success' : 'danger'"
                            :duration="3"
                            @elapsed="shareCollapseVisible = false"
                        >
                            <p class="m-0">{{ $t(shareSuccess ? 'share-success' : 'share-fail') }}</p>
                        </Alert>
                    </div>
                </template>
            </Collapsible>
        </div>
    </div>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {Formatter} from '@/lib/Formatter'
import LanguageDropdown from '../lib/LanguageDropdown.vue'
import Spinner from '../lib/Spinner.vue'
import InlineSvg from 'vue-inline-svg'
import copy from 'copy-to-clipboard'
import {useRouter, useRoute} from 'vue-router'
import {useRouterPath} from '../../lib/useRouterLinks.js'
import Collapsible from '../lib/Collapsible.vue'
import Alert from '../lib/Alert.vue'

const props = defineProps({
    video: {
        type: Object,
        required: true,
        validator: ({key}) => !!key,
    },
    subtitles: Array,
})
const emit = defineEmits(['locale'])

const videoKey = computed(() => {
    const {key} = props.video || {}
    return key
})
const locale = computed(() => {
    const {locale} = props.video || {}
    return locale
})
const locales = computed(() => {
    const {locales} = props.video || {}
    return locales
})
const src = computed(() => {
    const {src} = props.video || {}
    return src
})
const shareUrl = computed(() => {
    const {shareUrl} = props.video || {}
    return shareUrl
})
const preload = computed(() => {
    const {preload} = props.video || {}
    return preload || 'none'
})
const imageUrl = computed(() => {
    const {imageUrl} = props.video || {}
    return imageUrl
})
const shortDescription = computed(() => {
    const {shortDescription} = props.video || {}
    return shortDescription
})
const download = computed(() => {
    const {download} = props.video || {}
    return download
})
const duration = computed(() => {
    const {duration} = props.video || {}
    return duration
})

const shareHref = computed(() => {
    const {href} = shareUrl.value && new URL(shareUrl.value, window.location.href) || {}
    return href
})

const router = useRouter()
const route = useRoute()
const store = useStore()
const userLocale = computed(() => store.getters.locale)
const videoLocale = computed(() => locale.value || (locales.value || []).find(locale => locale === userLocale.value))

const subtitleItems = computed(() => {
    const subtitles = (props.subtitles || [])
        .map(file => file.match(/^[^_]+_(\w+)-?([^.]*)\.vtt$/))
        .filter(_ => _)
        .map(([file, code, suffix]) => ({file, code, suffix}))
    const hasDefault = subtitles.find(({code}) => code === videoLocale.value)

    return subtitles
        .map(({file, code, suffix}) => ({
            locale: code,
            file,
            label: `${store.getters.languageName(code)}${!suffix ? '' : ` (${Formatter.initialCaps(suffix)})`}` || code.toUpperCase(),
            isDefault: code === (!hasDefault ? userLocale.value : videoLocale.value),
        }))
        .sort(({locale: a}, {locale: b}) => a.localeCompare(b))
})

const videoPlayer = ref()
const loading = ref(true)
const started = ref(false)
const resumable = ref(false)
const currentTime = ref(0)

const setVideoLocale = locale => {
    resumable.value = !videoPlayer.value.paused
    emit('locale', locale)
}

const progressStep = 100 / 50
const getProgress = (time, duration) => ~~((time / duration) * 100 / progressStep) * progressStep
const trackVideoProgress = (time, duration) => store.dispatch('trackVideoProgress', {
    key: videoKey.value,
    locale: userLocale.value,
    progress: getProgress(time, duration),
})

const onPlay = () => started.value = true
const onTimeupdate = ({target: {currentTime: time, duration}}) => {
    currentTime.value = time
    trackVideoProgress(time, duration)
}
const onLoadedMetadata = ({target}) => target.currentTime = currentTime.value
const onCanPlay = ({target}) => {
    loading.value = false
    if (resumable.value && target.paused) {
        resumable.value = false
        target.play()
    }
}

const shareCollapseVisible = ref()
const shareSuccess = ref(false)
const routerPath = useRouterPath(shareUrl.value)

const onShareUrl = () => {
    const success = shareSuccess.value = copy(shareHref.value)
    if (!success && routerPath && route.fullPath !== shareUrl.value) {
        router.push(routerPath)
    } else {
        shareCollapseVisible.value = true
    }
}

watch(locale, () => loading.value = true)
</script>

<style scoped>
.poster {
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    & > .play {
        height: 1em;
        width: 1em;
        font-size: 8em;
        border-radius: 50%;
        background-color: #888;
        border: .075em solid white;
        opacity: .666;
        transition: opacity .3s ease;

        & > span {
            width: 0;
            height: 0;
            font-size: .666em;
            margin-left: .25em;
            border-top: .5em solid transparent;
            border-bottom: .5em solid transparent;
            border-left: .75em solid white;
        }
    }

    &:hover > .play {
        opacity: .85;
    }
}

.buttons {
    display: flex;
    gap: .5em;
}
</style>
