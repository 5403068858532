<template>
    <template v-for="({type, value, className = `content-block ${type}-content`}, index) in items">
        <Markdown
            v-if="type === 'text'"
            :key="`${index}-${type}`"
            :source="value"
            breaks
            :class="className"
        />
        <Button
            v-else-if="type === 'button'"
            :key="`${index}-${type}`"
            :button="value"
            :class="className"
        />
        <Images
            v-else-if="type === 'images'"
            :key="`${index}-${type}`"
            :images="value"
            :class="className"
        />
        <VideoPlayer
            v-else-if="type === 'video'"
            :key="`${index}-${type}`"
            :video="value"
            :class="className"
        />
        <MoviePlayer
            v-else-if="type === 'movie'"
            :key="`${index}-${type}`"
            :video="value"
            :class="className"
        />
        <Collapse
            v-else-if="type === 'collapse'"
            :key="`${index}-${type}`"
            class="content-block collapse-content"
        >
            <template #button-content-more>
                {{ value.button }}
            </template>
            <template #content>
                <Content :content="value.content" />
            </template>
        </Collapse>
        <ContactForm
            v-else-if="type === 'contact-form'"
            class="content-block contact-form-content"
        />
        <div v-else>
            UNKNOWN CONTENT TYPE: {{ type }}
        </div>
    </template>
</template>

<script setup>
import {computed} from 'vue'
import {Values} from '../../lib/Values.js'
import {Objects} from '../../lib/Objects.js'
import Collapse from './Collapse.vue'
import Images from './Images.vue'
import VideoPlayer from './VideoPlayer.vue'
import MoviePlayer from './MoviePlayer.vue'
import Markdown from './Markdown.vue'
import Button from './Button.vue'
import ContactForm from '../lib/ContactForm.vue'

const props = defineProps({
    content: {
        type: [String, Array, Object],
        required: true,
    },
})

const getTextItem = content => Values.isString(content) && [{type: 'text', value: content}]
const getObjectItems = content => Objects.isObject(content) && Object.entries(content).map(([type, value]) => ({type, value}))
const getArrayItems = content => Array.isArray(content) && content.flatMap(content => getItems(content))

const getItems = content => getTextItem(content) || getObjectItems(content) || getArrayItems(content)
const items = computed(() => props.content && getItems(props.content))
</script>

<style scoped>

</style>
