<template>
    <div :key="appKey">
        <Spinner
            v-if="loading"
            cover-absolute
            style="height: 100vh;"
        />
        <template v-else>
            <Header />
            <div class="content-container container-fluid">
                <router-view />
            </div>
            <Footer />
        </template>
    </div>
</template>

<script setup>
import {onMounted, ref, computed} from 'vue'
import {useStore} from 'vuex'
import {Sessions} from './lib/Session.js'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Spinner from './components/lib/Spinner.vue'

const appKey = ref(Sessions.createSessionId())

const store = useStore()

const locale = computed(() => store.getters.locale)
const translation = computed(() => store.getters.translation(locale.value))
const loading = computed(() => !translation.value)

onMounted(() => document.addEventListener('app-rerender', () => appKey.value = Sessions.createSessionId()))
</script>