<template>
    <div class="text-center py-3">
        <a
            :href="href"
            :class="buttonClass"
            :target="!routerPath && '_blank'"
            @click="navigate"
        >
            <component
                :is="isSvg ? InlineSvg : 'img'"
                v-if="icon"
                :src="icon.src"
                :alt="label"
                :height="icon.height"
            />
            <MarkdownText :source="label" />
        </a>
    </div>
</template>

<script setup>
import {computed} from 'vue'
import {useRouter} from 'vue-router'
import MarkdownText from '../lib/MarkdownText.vue'
import InlineSvg from 'vue-inline-svg'
import {useRouterPath} from '../../lib/useRouterLinks.js'

const props = defineProps({
    button: {
        type: Object,
        required: true,
        validator: ({label, href}) => !!label && !!href,
    },
})
const variant = computed(() => {
    const {variant} = props.button || {}
    return variant
})
const size = computed(() => {
    const {size} = props.button || {}
    return size
})
const icon = computed(() => {
    const {icon} = props.button || {}
    return icon
})
const iconAspectRatio = computed(() => {
    const {width, height} = icon.value || {}
    return width && height && width / height
})
const label = computed(() => {
    const {label} = props.button || {}
    return label
})
const href = computed(() => {
    const {href} = props.button || {}
    return href
})
const buttonClass = computed(() =>
    [
        icon.value && 'icon d-inline-flex align-items-center',
        ...(!variant.value && !size.value ? [] : ['btn', size.value && `btn-${size.value}`, variant.value && `btn-${variant.value}`]),
    ]
        .filter(Boolean)
        .join(' '))
const isSvg = computed(() => icon.value.src && icon.value.src.endsWith('.svg'))

const router = useRouter()
const routerPath = useRouterPath(href.value)
const navigate = event => {
    if (routerPath) {
        event.preventDefault()
        event.stopPropagation()
        router.push(routerPath)
        return false
    }
    return event
}
</script>

<style scoped>
.icon {
    gap: .5em;

    img, svg {
        height: 1.5em;
        aspect-ratio: v-bind(iconAspectRatio);
    }
}
</style>
