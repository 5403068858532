import {Objects} from './Objects.js'

export class Values {

    static isNumber(number) {
        return !Objects.not(number) && typeof number === 'number' && Number.isFinite(number)
    }

    static isInteger(number) {
        return Values.isNumber(number) && Number.isInteger(number)
    }

    static isFloat(number) {
        return Values.isNumber(number) && !Number.isInteger(number)
    }

    static isString(string) {
        return !Objects.not(string) && typeof string === 'string'
    }

    static isBoolean(boolean) {
        return !Objects.not(boolean) && typeof boolean === 'boolean'
    }
}