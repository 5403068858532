<template>
    <div
        ref="collapsible"
        class="collapse"
        :class="initiallyExpanded && 'show'"
    >
        <slot
            v-if="!lazy || isFrozen || expanded"
            :frozen="frozen"
        />
    </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, watch, onBeforeMount, nextTick} from 'vue'
import {Collapse} from 'bootstrap'

const props = defineProps({
    lazy: Boolean,
    expanded: Boolean,
    freeze: Object,
})
const emit = defineEmits(['hide', 'hidden', 'show', 'shown'])

const collapsible = ref()
const collapse = ref()
const initiallyExpanded = ref(props.expanded)
const isFrozen = ref(false)
const frozen = ref(props.freeze)

const onShow = () => emit('show')
const onShown = () => emit('shown')
const onHide = () => emit('hide')
const onHidden = () => {
    frozen.value = props.freeze
    isFrozen.value = false
    emit('hidden')
}

onBeforeMount(() => initiallyExpanded.value = props.expanded)
onMounted(() => {
    collapse.value = new Collapse(collapsible.value, {toggle: false})
    collapsible.value.addEventListener('show.bs.collapse', onShow)
    collapsible.value.addEventListener('shown.bs.collapse', onShown)
    collapsible.value.addEventListener('hide.bs.collapse', onHide)
    collapsible.value.addEventListener('hidden.bs.collapse', onHidden)
})
onBeforeUnmount(() => {
    collapsible.value.removeEventListener('hidden.bs.collapse', onHidden)
    collapsible.value.removeEventListener('hide.bs.collapse', onHide)
    collapsible.value.removeEventListener('shown.bs.collapse', onShown)
    collapsible.value.removeEventListener('show.bs.collapse', onShow)
    collapse.value.dispose()
})
watch(() => props.expanded, expanded => {
    isFrozen.value = !expanded
    if (!expanded) {
        collapse.value.hide()
    } else {
        nextTick(() => setTimeout(() => collapse.value.show(), 0))
    }
})
watch(() => props.freeze, data => nextTick(() => {
    if (!isFrozen.value) {
        frozen.value = data
    }
}))
</script>
