import {Arrays} from './Arrays.js'

export class Objects {

    static not(value) {
        return value === null || value === undefined
    }

    static isObject(object) {
        return object && Object.prototype.toString.call(object) === '[object Object]'
    }

    static isEmpty(object) {
        return Objects.not(object) || !Object.keys(object).length
    }

    static equalsObject(a, b) {
        return Objects.not(a) === Objects.not(b) || Objects.isObject(a) && Objects.isObject(b) && Arrays.matches(Object.keys(a), Object.keys(b))
    }

    static shallowEquals(a, b) {
        if (Objects.not(a) && Objects.not(b)) {
            return true
        }
        if (Objects.not(a) !== Objects.not(b)) {
            return false
        }
        const keys = Object.keys(a)
        return keys.length === Object.keys(b).length && keys.every(key => a[key] === b[key] || Arrays.similar(a[key], b[key]))
    }

    static deepEquals(a, b) {
        const keys = Object.keys(a || {})
        return !a && !b || keys.length === Object.keys(b).length && keys.every(key => a[key] === b[key] || Arrays.similar(a[key], b[key]) || Objects.equalsObject(a[key], b[key]) && Objects.deepEquals(a[key], b[key]))
    }

    static filterProps(keys, object, predicate = _ => !Objects.not(_)) {
        return keys.reduce((filtered, key) => ({...filtered, ...(predicate && !predicate(object[key]) ? {} : {[key]: object[key]})}), {})
    }

    static deepMerge(target, ...sources) {
        return sources.reduce((target, source) => Object.entries(source).reduce((target, [key, value]) => ({...target, [key]: !Objects.isObject(value) ? value : Objects.deepMerge(target[key], value)}), target), {...target})
    }

    static recursiveFilterProps(object, predicate = _ => !Objects.not(_)) {
        return Object.entries(object).reduce((filtered, [key, value]) => ({...filtered, ...(Objects.isObject(value) ? {[key]: Objects.recursiveFilterProps(value, predicate)} : !predicate(value, key) ? {} : {[key]: value})}), {})
    }

    static removeProps(object, ...keys) {
        return Object.fromEntries(Object.entries(object).filter(([key]) => !keys.includes(key)))
    }

    static recursiveProps(object) {
        return Object.entries(object).map(([key, value]) => typeof value === 'object' && value !== null ? Objects.recursiveProps(value) : [[key, value]]).flat()
    }

    static clone(object) {
        return object && JSON.parse(JSON.stringify(object))
    }

    static setProperties(object, properties) {
        Object
            .entries(properties)
            .forEach(([key, value]) => Object.defineProperty(object, key, {get: () => value}))

    }
}
