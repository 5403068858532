import {StoreId} from './StoreId.js'

export class ValueByIdStore {

    static createState() {
        return {
            ids: [],
            values: {},
        }
    }

    static valuesById(values) {
        return values.reduce((valuesById, value) => ({...valuesById, [StoreId.stringifyId(value.id)]: value}), {})
    }

    static setValue(state, value) {
        return ValueByIdStore.setValueById(state, value.id, value)
    }

    static setValueById(state, id, value) {
        const storeId = StoreId.stringifyId(id)
        if (!Object.prototype.hasOwnProperty.call(state.values, storeId)) {
            state.ids = [...state.ids, id]
        }
        state.values[storeId] = value
        return state
    }

    static setValues(state, values) {
        const valuesById = ValueByIdStore.valuesById(values)
        const ids = Object.entries(valuesById).reduce((ids, [storeId, {id, ...value}]) => {
            if (!Object.prototype.hasOwnProperty.call(state.values, storeId)) {
                ids = [...ids, id]
            }
            state.values[storeId] = {...value, id}
            return ids
        }, [])
        if (ids.length) {
            state.ids = [...state.ids, ...ids]
        }
        return state
    }

    static removeValueById(state, id) {
        const storeId = StoreId.stringifyId(id)
        if (Object.prototype.hasOwnProperty.call(state.values, storeId)) {
            state.ids = state.ids.filter(other => other !== id)
            delete state.values[storeId]
        }
        return state
    }

    static removeValuesById(state, ids) {
        ids = [...new Set(ids)].reduce((ids, id) => !Object.prototype.hasOwnProperty.call(state.values, StoreId.stringifyId(id)) ? ids : [...ids, id], [])
        state.ids = state.ids.filter(id => !ids.includes(id))
        ids.forEach(id => delete state.values[StoreId.stringifyId(id)])
        return state
    }

    static getValue(state) {
        return id => ValueByIdStore.getValueById(state, id)
    }

    static getValueById(state, id) {
        return state.values[StoreId.stringifyId(id)]
    }

    static getIds(state) {
        return state.ids
    }

    static getValues({values}) {
        return Object.values(values)
    }

    static replaceValues(state, values) {
        const ids = new Set(values.reduce((ids, {id, ...value}) => {
            state.values[StoreId.stringifyId(id)] = {...value, id}
            return [...ids, id]
        }, []))
        state.ids.forEach(id => !ids.has(id) && delete state.values[StoreId.stringifyId(id)])
        state.ids = ids
        return state
    }

    static clear(state) {
        Object
            .entries(ValueByIdStore.createState())
            .forEach(([key, value]) => state[key] = value)
        return state
    }
}