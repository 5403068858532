import {onMounted, onUpdated, watch} from 'vue'
import {useRouter} from 'vue-router'

const getUrl = url => new URL(url, window.location.href)
const getLocalPath = url => {
    const {href, origin} = getUrl(url)
    return origin === window.location.origin && href.replace(origin, '')
}
const isRouterPath = (router, url) => {
    const localPath = url && getLocalPath(url)
    const {matched, params: {catchAll} = {}} = localPath && router.resolve(localPath) || {}
    return !!matched && !catchAll
}
const getRouterPath = (router, url) => {
    if (isRouterPath(router, url)) {
        const {href, origin} = getUrl(url)
        return href.replace(origin, '')
    }
}

export const useRouterPath = url => {
    const router = useRouter()
    return getRouterPath(router, url)
}

export const useRouterLinks = contentRef => {
    const router = useRouter()
    const updateLinks = () => contentRef.value && Array.from(contentRef.value.querySelectorAll('[href]')).forEach(link => {
        const path = getRouterPath(router, link.href)
        if (path) {
            link.addEventListener('click', event => {
                event.preventDefault()
                event.stopPropagation()
                router.push(path)
                return false
            })
        }
    })

    onMounted(updateLinks)
    onUpdated(() => setTimeout(updateLinks, 0))
    watch(contentRef, updateLinks)
}
