import {createRouter, createWebHistory} from 'vue-router'
import {Objects} from './lib/Objects.js'
import Home from './views/Home.vue'
import Movie from './views/Movie.vue'
import Restricted from './views/Restricted.vue'
import Contact from './views/Contact.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import Imprint from './views/Imprint.vue'

export class Router {
    router

    constructor(storeInstance) {
        const {store} = storeInstance
        const homeReady = store.getters.homeReady ? Promise.resolve() : new Promise(resolve => store.watch((_state, getters) => getters.homeReady, homeReady => resolve(homeReady)))
        const router = createRouter({
            history: createWebHistory(),
            routes: [
                {
                    path: '/',
                    name: 'home',
                    component: Home,
                },
                {
                    path: '/dominion/:locale(\\w{2})?',
                    name: 'dominion',
                    component: Movie,
                    props: ({params}) => ({...params, videoKey: 'dominion'}),
                },
                {
                    path: '/earthlings/:locale(\\w{2})?',
                    name: 'earthlings',
                    component: Movie,
                    props: ({params}) => ({...params, videoKey: 'earthlings'}),
                },
                {
                    path: '/8min/:locale(\\w{2})?',
                    name: '8min',
                    component: Movie,
                    props: ({params}) => ({...params, videoKey: '8min'}),
                },
                {
                    path: '/restricted',
                    name: 'restricted',
                    component: Restricted,
                },
                {
                    path: '/contact',
                    name: 'contact',
                    component: Contact,
                },
                {
                    path: '/privacy-policy',
                    name: 'privacyPolicy',
                    component: PrivacyPolicy,
                },
                {
                    path: '/imprint',
                    name: 'imprint',
                    component: Imprint,
                },
                {
                    path: '/stats/:timeFrame(\\d+\\w\\-?\\d*)?/:locale(\\w{2})?/:videoKey([-\\w]+)?',
                    name: 'stats',
                    beforeEnter: (to, from, next) => import(/* webpackChunkName: "stats" */ './store/StatsStore.js')
                        .then(storeInstance.addModules)
                        .catch(console.error)
                        .then(next),
                    component: () => import(/* webpackChunkName: "stats" */ './views/Stats.vue'),
                    props: ({params}) => params,
                },
                {
                    path: '/:catchAll(.*)',
                    redirect: {
                        name: 'home',
                    },
                },
            ],
            scrollBehavior: ({hash, fullPath: to, name}, {fullPath: from}, savedPosition) => to !== from && (name === 'home' ? homeReady : Promise.resolve())
                .then(() => new Promise(resolve => setTimeout(resolve, 133)))
                .then(() => hash && {
                        el: hash,
                        behavior: 'smooth',
                    } || savedPosition || {
                        top: 0,
                        behavior: 'smooth',
                    },
                ),
        })

        Objects.setProperties(this, {router})
    }
}

