<template>
    <section>
        <Spinner
            v-if="loading"
            class="w-100 my-5 py-5"
        />
        <Markdown
            v-else-if="content"
            :source="content"
            breaks
            class="text-content"
        />
    </section>
</template>

<script setup>
import {onBeforeMount, computed, ref, watch} from 'vue'
import {useStore} from 'vuex'
import Spinner from '../components/lib/Spinner.vue'
import Markdown from '../components/content/Markdown.vue'

const store = useStore()
const loading = ref(false)

const locale = computed(() => store.getters.locale)
const content = computed(() => store.getters.imprint(locale.value))

const load = locale => {
    loading.value = true
    store
        .dispatch('loadImprint', locale)
        .then(() => loading.value = false)
}
onBeforeMount(() => load(locale.value))
watch(locale, locale => load(locale))
</script>