<template>
    <footer
        id="footer"
        class="mt-3 py-3"
    >
        <p class="mb-2 text-center text-muted small">
            🍪 {{ $t('freeFromCookies') }}
        </p>
        <ul class="m-0 p-0 text-center">
            <li
                v-for="name in routes"
                :key="name"
                class="d-inline mx-2"
            >
                <router-link
                    :to="{name}"
                    class="text-muted"
                >
                    {{ $t(`${name}.title`) }}
                </router-link>
            </li>
        </ul>
    </footer>
</template>

<script setup>
import {ref} from 'vue'

const routes = ref([
    'contact',
    'imprint',
    'privacyPolicy',
])
</script>

<style scoped>

</style>