import {StoreId} from './StoreId.js'

export class StoreCache {

    constructor() {
        const cache = new Map()

        const stringifyId = id => StoreId.stringifyId(id)
        const withId = (id, callback) => callback(stringifyId(id))

        this.has = key => cache.has(stringifyId(key))
        this.get = key => cache.get(stringifyId(key))
        this.cached = (key, loader) => withId(key, id => (cache.has(id) ? cache : cache.set(id, loader(key))).get(id))
        this.clear = key => cache.delete(stringifyId(key))
        this.clearAll = () => cache.clear()
    }
}
