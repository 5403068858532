export class Arrays {

    static chunk(values, chunkSize) {
        return Object.values(values
            .map((value, index) => ({value, index: ~~(index / chunkSize)}))
            .reduce((byChunk, {value, index}) => ({
                ...byChunk,
                [index]: [...(byChunk[index] || []), value],
            }), {}),
        )
    }

    static similar(a, b) {
        return Array.isArray(a) && Array.isArray(b) && a.length === b.length
    }

    static equals(a, b) {
        return Arrays.similar(a, b) && a.reduce((equals, a, index) => equals && a === b[index], true)
    }

    static matches(a, b) {
        return Arrays.similar(a, b) && a.every(b.includes.bind(b))
    }
}