<template>
    <Spinner
        v-if="loading"
        class="w-100 my-5 py-5"
    />
    <template
        v-else
        v-for="({type, id, title, content}, index) in items"
    >
        <section
            v-if="type === 'card'"
            :key="`${index}-${type}`"
            :id="id"
            :class="type"
        >
            <div
                class="sticky-top bg-black"
                style="top: 2.5rem"
            >
                <div class="pt-3 pb-2 bg-dark">
                    <h2 class="m-0 px-2">
                        {{ index + 1 }}.
                        {{ title }}
                    </h2>
                </div>
            </div>
            <div class="card-content">
                <Content :content="content" />
            </div>
        </section>
        <section
            v-else-if="type === 'section'"
            :key="`${index}-${type}`"
            :class="type"
        >
            <div
                class="sticky-top pt-3 bg-black"
                style="top: 2.5rem"
            >
                <h2 class="m-0">{{ title }}</h2>
            </div>
            <Content :content="content" />
        </section>
    </template>
</template>

<script setup>
import {computed, onUpdated, onMounted, ref, onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import Content from '../components/content/Content.vue'
import Spinner from '../components/lib/Spinner.vue'

const store = useStore()
const loading = ref(false)

const locale = computed(() => store.getters.locale)
const contents = computed(() => store.getters.contents(locale.value))
const items = computed(() => (contents.value || [])
    .flatMap(item => Object.entries(item))
    .map(([type, content]) => ({...content, type})),
)

const updateLinks = () => Array.from(document.links).forEach(link => {
    const {hostname, pathname} = link
    if (hostname !== window.location.hostname || pathname.match(/\.\w{2,4}$/)) {
        link.target = '_blank'
    }
})
const load = locale => {
    loading.value = true
    return Promise.all([
        store.dispatch('loadMovies', locale),
        store.dispatch('loadContents', locale),
    ])
        .catch(console.error)
        .then(() => {
            store.commit('setHomeReady', true)
            loading.value = false
        })
}

onBeforeMount(() => locale.value && load(locale.value))
onMounted(updateLinks)
onUpdated(() => setTimeout(updateLinks, 0))
</script>
