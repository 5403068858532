<template>
    <header
        id="header"
        class="sticky-top"
    >
        <div class="bg-dark">
            <div
                class="content-container header-bar m-auto p-2 bg-dark"
                :class="showBack && 'show-back'"
            >
                <div
                    v-if="showBack"
                    class="ms-1"
                >
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="router.push({name: 'home'})"
                    >
                        &laquo;&nbsp;{{ $t('back') }}
                    </button>
                </div>
                <h1>
                    <router-link :to="{name: 'home'}">
                        <small class="an3x">an3x.org</small>
                        <span class="small">The Truth about <strong>Animal 3xploitation</strong></span>
                    </router-link>
                </h1>
            </div>
        </div>
    </header>
    <LanguageBar class="mt-3" />
</template>

<script setup>
import {computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import LanguageBar from './LanguageBar.vue'

const router = useRouter()
const route = useRoute()

const showBack = computed(() => {
    const {path} = route
    return !!path
        .replace(/^\//, '')
        .length
})
</script>

<style
    scoped
    lang="scss"
>
.reset {
    color: var(--text-color-default);

    &, &:hover {
        text-decoration: none;
    }
}

.header-bar {
    h1 {
        margin: 0;
        font-size: 1em;
        font-weight: normal;

        & > a {
            @extend .reset;

            display: flex;
            flex-flow: column;
            align-items: center;
            text-align: center;
        }
    }

    &.show-back {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        & > a {
            margin: 0 .5rem;
        }

        h1 > a {
            align-items: flex-end;
            text-align: right;
        }
    }
}
</style>
