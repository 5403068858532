import {createApp} from 'vue'
import {Sessions} from './lib/Session.js'
import {Internationalization} from './i18n/Internationalization.js'
import {Store} from './store.js'
import {Router} from './router.js'

import './assets/bootstrap-custom.scss'
import './assets/style.scss'

import App from './App.vue'

const internationalization = new Internationalization(Sessions.restore())
const storeInstance = new Store(internationalization)

const {i18n, locale} = internationalization
const {store} = storeInstance
const {router} = new Router(storeInstance)

store
    .dispatch('setLocale', locale)
    .catch(console.error)

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .mount('#app')
