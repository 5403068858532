<template>
    <div class="d-flex align-items-center justify-content-center">
        <span class="me-2">
            {{ $t('language') }}:
        </span>
        <div class=" position-relative">
            <LanguageDropdown
                :disabled="loading"
                :model-value="locale"
                :locales="availableLocales"
                @update:modelValue="setLocale"
            />
            <Spinner
                v-if="loading"
                small
                cover-absolute
            />
        </div>
    </div>
</template>

<script setup>
import {computed, ref, onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import Spinner from './lib/Spinner.vue'
import LanguageDropdown from './lib/LanguageDropdown.vue'

const store = useStore()
const loading = ref(false)

const locale = computed(() => store.getters.locale)
const availableLocales = computed(() => store.getters.availableLocales)

const setLocale = locale => {
    loading.value = true
    store
        .dispatch('setLocale', locale)
        .catch(console.error)
        .then(() => loading.value = false)
}

onBeforeMount(() => {
        loading.value = true
        store
            .dispatch('loadAvailableLocales')
            .then(() => loading.value = false)
    },
)
</script>