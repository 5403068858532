<template>
    <div ref="wrapper">
        <div class="d-flex justify-content-center">
            <button
                type="button"
                class="btn btn-icon btn-primary my-3"
                :class="!visible ? 'align-items-end' : 'align-items-center'"
                @click="toggle"
            >
                <template v-if="!visible">
                    <slot name="button-content-more">
                        {{ $t('readMore') }}
                    </slot>
                    <InlineSvg
                        src="./assets/svg/caret-down.svg"
                        :alt="$t('readMore')"
                    />
                </template>
                <template v-else>
                    <slot name="button-content-less">
                        {{ $t('hide') }}
                    </slot>
                    <InlineSvg
                        src="./assets/svg/caret-up.svg"
                        :alt="$t('readMore')"
                    />
                </template>
            </button>
        </div>
        <div
            ref="collapse"
            class="collapse mt-2"
        >
            <slot name="content" />
            <div class="text-center">
                <button
                    type="button"
                    class="btn btn-icon btn-outline-primary my-3"
                    @click="toggle"
                >
                    <slot name="button-content-less">
                        {{ $t('hide') }}
                    </slot>
                    <InlineSvg
                        src="/assets/svg/caret-up.svg"
                        :alt="$t('readMore')"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref} from 'vue'
import {Collapse} from 'bootstrap'
import InlineSvg from 'vue-inline-svg'

const wrapper = ref()
const collapse = ref()
const visible = ref(false)
const controller = ref()

onMounted(() => {
    controller.value = new Collapse(collapse.value, {toggle: visible.value})
    collapse.value.addEventListener('shown.bs.collapse', () => wrapper.value.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
    }))
    collapse.value.addEventListener('hidden.bs.collapse', () => wrapper.value.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
    }))
})
onBeforeUnmount(() => controller.value.dispose())

const toggle = () => (visible.value = !visible.value) ? controller.value.show() : controller.value.hide()
</script>
