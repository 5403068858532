import {Objects} from './Objects.js'

export class Formatter {

    static formatDecimal(value, locale = 'en') {
        return new Intl
            .NumberFormat(locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            .format(value)
    }

    static formatPercent(value, locale = 'en') {
        return new Intl
            .NumberFormat(locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'percent',
            })
            .format(value)
    }

    static formatInteger(value, locale = 'en') {
        return new Intl
            .NumberFormat(locale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            })
            .format(value)
    }

    static formatCurrency(value, {currency = 'EUR', locale = 'en'} = {}) {
        const formattedValue = Formatter.formatDecimal(value, locale)
        return `${currency.toUpperCase()} ${!Number.isInteger(value) ? formattedValue : formattedValue.replace(/0+$/, '-')}`
    }

    static formatDate(value, {locale = 'de', ...format} = {}) {
        const defaultFormat = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
        }
        const {weekday, day, month, year} = new Intl
            .DateTimeFormat(locale, {...defaultFormat, ...Objects.filterProps(Object.keys(format), format)})
            .formatToParts(value)
            .reduce((parts, {type, value}) => type === 'literal' || format[type] === null ? parts : {...parts, [type]: value}, {})
        return [
            weekday && `${weekday}${!day && !month && !year ? '' : ','}`,
            day && `${day}.`,
            month,
            year,
        ]
            .filter(_ => _)
            .join(' ')
    }

    static formatTime(date, {locale = 'de', ...format} = {}) {
        const defaultFormat = {
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
        }
        const {hour, minute, second} = new Intl
            .DateTimeFormat(locale, {...defaultFormat, ...Objects.filterProps(Object.keys(format), format)})
            .formatToParts(date)
            .reduce((parts, {type, value}) => type === 'literal' || format[type] === null ? parts : {...parts, [type]: value}, {})
        return [hour, minute, second]
            .filter(_ => _)
            .join(':')
    }

    static formatDateTime(date, {locale = 'de', year, month, day, weekday, hour, minute, second} = {}) {
        return `${Formatter.formatDate(date, {locale, year, month, day, weekday})} ${Formatter.formatTime(date, {locale, hour, minute, second})}`
    }

    static formatDuration({s = 0, m = 0, h = 0, d = 0}) {
        h += (d - ~~d) * 24
        d = ~~d
        m += (h - ~~h) * 60
        h = ~~h
        s += (m - ~~m) * 60
        m = ~~m
        m += ~~(s / 60)
        s %= 60
        h += ~~(m / 60)
        m %= 60
        d += ~~(h / 24)
        h %= 24
        return `${!d ? '' : `${Formatter.formatInteger(d)}d `}${h.toFixed(0)}:${m.toFixed(0).padStart(2, '0')}:${s.toFixed(0).padStart(2, '0')}h`
    }

    static capitalize(word) {
        return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`
    }

    static initialCaps(value = '', separator = ' ') {
        return value.split(separator).map(Formatter.capitalize).join(separator)
    }

    static splitCamelCase(value) {
        return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(\d+)/g, ' $1 ').trim()
    }

    static camelCase(value) {
        return Formatter.capitalize(Formatter.splitCamelCase(value))
    }
}
