import {ValueByIdStore} from '../lib/ValueByIdStore.js'
import {StoreCache} from '../lib/StoreCache.js'

const subtitlesCache = new StoreCache()
export const SubtitlesStore = {
    state: ValueByIdStore.createState(),
    actions: {
        loadSubtitles: ({getters: {httpClient}, commit}, videoKey) => subtitlesCache.cached(`api/subtitles/${videoKey}`, url => httpClient
            .get(url)
            .then(values => commit('setSubtitles', {id: videoKey, values})),
        ),
    },
    mutations: {
        setSubtitles: ValueByIdStore.setValue,
    },
    getters: {
        subtitles: state => videoKey => (ValueByIdStore.getValueById(state, videoKey) || {}).values,
    }
}