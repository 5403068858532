<template>
    <div
        v-bind="$attrs"
        class="position-relative"
        :style="loading && 'aspect-ratio: 16 / 9;'"
    >
        <Spinner
            v-if="loading"
            cover-absolute
            class="h-auto"
            style="aspect-ratio: 16 / 9;"
        />
        <VideoPlayer
            v-else-if="video"
            :video="video"
            :subtitles="subtitles"
            class="content-block video-content"
            @locale="setLocale"
        />
    </div>
    <Markdown
        v-if="showDescription && description"
        :source="description"
    />
</template>

<script setup>
import {computed, ref, onBeforeMount, watch} from 'vue'
import {useStore} from 'vuex'
import VideoPlayer from './VideoPlayer.vue'
import Markdown from './Markdown.vue'
import Spinner from '../lib/Spinner.vue'

defineOptions({
    inheritAttrs: false,
})
const props = defineProps({
    video: {
        type: Object,
        required: true,
        validator: ({key}) => !!key,
    },
    locale: String,
    showDescription: Boolean,
})
const emit = defineEmits(['locale'])

const store = useStore()

const loading = ref(false)
const audioTrack = ref(props.locale)

const videoKey = computed(() => {
    const {key} = props.video || {}
    return key
})
const userLocale = computed(() => store.getters.locale)
const movies = computed(() => store.getters.movies(userLocale.value))
const movie = computed(() => (movies.value || []).find(({key}) => key === videoKey.value))
const description = computed(() => {
    const {description} = movie.value || {}
    return description
})
const sources = computed(() => {
    const {sources} = movie.value || {}
    return sources
})

const findSource = locale => sources.value && sources.value[locale] && Object
    .entries(sources.value)
    .filter(([videoLocale]) => locale === videoLocale)
    .map(([locale, src]) => ({locale, src}))
    .pop()

const video = computed(() => {
    const {locale, src} = findSource(props.locale || audioTrack.value || userLocale.value) || findSource(store.getters.fallbackLocale) || {}
    return src && {
        ...props.video,
        ...(movie.value || {}),
        src,
        locale,
        locales: Object.keys(sources.value),
    }
})
const subtitles = computed(() => store.getters.subtitles(videoKey.value))

const setLocale = locale => {
    audioTrack.value = locale
    emit('locale', locale)
}
const load = () => {
    loading.value = true
    Promise
        .all([
            userLocale.value && store.dispatch('loadMovies', userLocale.value),
            videoKey.value && store.dispatch('loadSubtitles', videoKey.value),
        ])
        .catch(console.error)
        .then(() => loading.value = false)
}
onBeforeMount(load)
watch(userLocale, load)
watch(videoKey, load)
</script>

<style scoped>

</style>
