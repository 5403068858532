import {Objects} from './Objects.js'

export class Contents {

    static findVideos(contents = []) {
        let items = [...contents]
        let videos = []
        while (items.length) {
            const item = items.shift()
            if (Objects.isObject(item)) {
                const {video} = item
                if (Objects.isObject(video)) {
                    videos = [...videos, video]
                }
                items = [
                    ...items,
                    ...Object
                        .values(item)
                        .flat()
                        .flatMap(item => Objects.isObject(item) && item.content || []),
                ]
            }
        }
        return videos
    }
}