<template>
    <div
        v-if="!items.length"
        class="d-flex align-items-center pe-4 rounded bg-dark overflow-hidden"
    >
        <LocaleIcon :locale="modelValue" />
    </div>
    <div
        v-else
        class="dropdown"
    >
        <button
            type="button"
            :disabled="disabled"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            class="dropdown-toggle btn btn-dark d-flex align-items-center ps-0 py-0 overflow-hidden"
        >
            <LocaleIcon
                :locale="modelValue"
                class="me-3"
            />
        </button>
        <ul class="dropdown-menu p-0 bg-black">
            <li
                v-for="locale in items"
                :key="locale"
                class="my-1 rounded overflow-hidden bg-dark"
            >
                <button
                    type="button"
                    :disabled="disabled"
                    class="btn btn-dark d-flex w-100 p-0"
                    @click="update(locale)"
                >
                    <LocaleIcon
                        :locale="locale"
                        class="flex-grow-1"
                    />
                </button>
            </li>
        </ul>
    </div>
</template>

<script setup>
import {computed, nextTick} from 'vue'
import LocaleIcon from './LocaleIcon.vue'

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    locales: {
        type: Array,
        default: () => [],
    },
    disabled: Boolean,
})
const emit = defineEmits(['update:modelValue'])
const update = locale => nextTick(() => setTimeout(() => emit('update:modelValue', locale), 0))

const items = computed(() => props.locales.filter(other => other !== props.modelValue))
</script>
