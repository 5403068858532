import {computed} from 'vue'
import MarkdownIt from 'markdown-it'

export const MarkdownProps = {
    breaks: {
        type: Boolean,
        default: false,
    },
    html: {
        type: Boolean,
        default: false,
    },
    langPrefix: {
        type: String,
        default: 'language-',
    },
    linkify: {
        type: Boolean,
        default: false,
    },
    plugins: {
        type: Array,
        default: () => [],
    },
    quotes: {
        type: String,
        default: '“”‘’',
    },
    typographer: {
        type: Boolean,
        default: false,
    },
    xhtmlOut: {
        type: Boolean,
        default: false,
    },
}

export const useMarkdown = ({plugins, ...options}) => {
    const {
        breaks,
        html,
        langPrefix,
        linkify,
        quotes,
        typographer,
        xhtmlOut,
    } = options
    const markdown = computed(() => {
        const markdown = new MarkdownIt().set({
            breaks,
            html,
            langPrefix,
            linkify,
            quotes,
            typographer,
            xhtmlOut,
        })
        plugins.forEach(({plugin, options = {}}) => markdown.use(plugin, options))
        return markdown
    })
    return {
        render: markdown.value.render.bind(markdown.value),
        renderInline: markdown.value.renderInline.bind(markdown.value),
    }
}
